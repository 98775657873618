@font-face {
    font-family: 'icons';
    //src: url('/templates/mccarthyvw/fonts/mccarthyvw-icon.eot?l93tao');
    //src: url('/templates/mccarthyvw/fonts/mccarthyvw-icon.eot?l93tao#iefix') format('embedded-opentype'),
      //  url('/templates/mccarthyvw/fonts/mccarthyvw-icon.ttf?l93tao') format('truetype'),
        //url('/templates/mccarthyvw/fonts/mccarthyvw-icon.woff?l93tao') format('woff'),
        src:  url('assets/icons.eot?3ec8v7');
  src:  url('assets/icons.eot?3ec8v7#iefix') format('embedded-opentype'),
    url('assets/icons.ttf?3ec8v7') format('truetype'),
    url('assets/icons.woff?3ec8v7') format('woff'),
    url('assets/icons.svg?3ec8v7#icons') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

[class^="icon-"],
[class*=" icon-"] 
    {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icons' !important;
    //speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-arrowleft:before{
    content: "\e902";
}

.icon-arrowleft2:before{
    content: "\e903";
}

.icon-arrowright:before{
    content: "\e905";
}

.icon-arrowright2:before{
    content: "\e906";
}

.icon-search:before{
    content: "\e91d";
}

.icon-home:before{
    content: "\e91a";
}

.icon-buy:before{
    content: "\e90b";
}

.icon-contact:before{
    content: "\e90d";
}

.icon-sell:before{
    content: "\e926";
}

.icon-service:before{
    content: "\e927";
}

.icon-parts:before{
    content: "\e921";
}

.icon-dealers:before{
    content: "\e90f";
}

.icon-new:before{
    content: "\e920";
}

.icon-used:before{
    content: "\e924";
}

.icon-demo:before{
    content: "\e90a";
}

.icon-specials:before{
    content: "\e928";
}

.icon-news:before{
    content: "\e91f";
}

.icon-finance:before{
    content: "\e917";
}

.icon-favourite:before{
    content: "\e915";
}

.icon-phone:before{
    content: "\e922";
}

.icon-headset:before{
    content: "\e90e";
}

.icon-info:before{
    content: "\e91b";
}

.icon-financecircle:before{
    content: "\e918";
}

.icon-starfull:before{
    content: "\e929";
}
.icon-star:before{
    content: "\e92a";
}
.icon-calccircle:before{
    content: "\e909";
}
.icon-scalecircle:before{
    content: "\e925";
}
.icon-magcircle:before{
    content: "\e90c";
}

.icon-thumb:before{
    content: "\e92b";
}
.icon-youtube:before{
    content: "\e92d";
}
.icon-facebook:before{
    content: "\e914";
}
.icon-instagram:before{
    content: "\e91c";
}
.icon-twitter:before{
    content: "\e92c";
}
.icon-back:before{
    content: "\ea40";
}
.icon-cross:before{
    content: "\ea0f";
}
.icon-brocurecircle:before{
    content: "\e908";
}

.icon-sort:before{
    content: "\ea4d";
}

.icon-clock:before{
    content: "\e94e";
}


.icon-toyotalogo:before{
    content: "\e92f";
}

.icon-shield:before{
    content: "\e919";
}

.icon-steeringwheel:before{
    content: "\e92e";
}




.icon-specialsall:before{
    content: "\e931";
}
.icon-specialsnew:before{
    content: "\e932";
}
.icon-specialsused:before{
    content: "\e935";
}
.icon-specialsdemo:before{
    content: "\e930";
}
.icon-specialsservice:before{
    content: "\e936";
}
.icon-specialsparts:before{
    content: "\e934";
}
.icon-specialsother:before{
    content: "\e933";
}
.icon-sellnocircle:before{
    content: "\e937";
}
.icon-buynocircle:before{
    content: "\e938";
}
.icon-homenocircle:before{
    content: "\e93b";
}
.icon-fleet:before{
    content: "\e93c";
}
.icon-Accessories2:before{
    content: "\e93d";
}
.icon-accessories-circle:before{
    content: "\e93e";
}
.icon-linkedin:before{
    content: "\e93f";
}
.icon-whatsapp:before{
    content: "\e940";
}
.icon-twitter2:before{
    content: "\e984";
}
.icon-truck:before{
    content: "\e987";
}
.icon-ribbon-check:before{
    content: "\e986";
}
.icon-cellphone:before{
    content: "\e985";
}
.icon-truck-circle:before{
    content: "\e988";
}
.icon-new-truck:before{
    content: "\e989";
}