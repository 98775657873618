/* You can add global styles to this file, and also import other style files */
@import 'icons.less';


.root{
    min-height: 110%;
}
p{
    font-size: 0.9rem;
}
.rem09{
    li, ul{
        font-size: 0.9rem;
    }
}
.vert-cent-margin {
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.h-fit-content {
    height: fit-content !important;
}

.w-fit-content {
    width: fit-content !important;
}

select {
    color: black;
    background: transparent;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}

.selectbox {
    border: 1px solid #CCCCCC;
    background-color: white;
    border-radius: 5px;
    padding: 0 5px;
    margin-bottom: 10px;

    &:has(select) {
        background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDE2IDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik04LjIwMTQyIDguMjA2MjlMMTUuOTE2OSAwLjQ4NjgyOEMxNi4wMjc3IDAuMzc1NTU2IDE2LjAyNzcgMC4xOTQ4NTUgMTUuOTE2OSAwLjA4MzQ4MzNDMTUuODA5IC0wLjAyNDQzOTkgMTUuNjIxOSAtMC4wMjQ0Mzk5IDE1LjUxNSAwLjA4MzQ4MzNMNy45OTk4MiA3LjYwMTUxTDAuNDg2MDggMC4wODM0ODMzQzAuNDMyMzk3IDAuMDMwMjQ2IDAuMzYwMDc0IDAgMC4yODQ0MDUgMEMwLjIwODczNiAwIDAuMTM2NDEzIDAuMDMwMjQ2IDAuMDgyNzI5NiAwLjA4MzQ4MzNDLTAuMDI3NjUwOCAwLjE5NDc1NSAtMC4wMjc2NTA4IDAuMzc1NDU2IDAuMDgzMTc2IDAuNDg2ODI4TDcuNzk4MDYgOC4yMDU3MUM3LjkwNzEgOC4zMTM2NCA4LjA5MzYxIDguMzE0MiA4LjIwMTUyIDguMjA2MjdMOC4yMDE0MiA4LjIwNjI5WiIgZmlsbD0iIzMzMzMzMyIvPgo8L3N2Zz4K) no-repeat 95% 50%;
    }

    .select-radius {
        border-left: 1px solid var(--primary);
    }

    select {

        height: 25px;
        outline: none;
        width: 100%;
        border: 0px;
        padding-left: 7px;
        color: black;
    }

    input,
    textarea {
        height: 50px;
        outline: none;
        width: 100%;
        border: 0px;
        //border-left: 1px solid var(--primary);
        padding-left: 7px;
    }

    p {
        height: 25px;
        margin: 0;
        //border-left: 1px solid var(--primary);
        padding-left: 7px;
        font-weight: 400;
        color: gray;
        font-size: 0.8rem;
    }
}

.btn-primary {
    border-radius: 5px;
    margin: 2px;
    background-color: var(--primary);
    border-color: var(--primary);
    border-style: solid;
    border-width: 1px;
    color: white;
    height: 40px;
}

.btn-primary-nomar {
    border-radius: 5px;
    margin-top: 2px;
    margin-right: 2px;
    margin-bottom: 2px;
    background-color: var(--primary);
    border-color: var(--primary);
    border-style: solid;
    border-width: 1px;
    color: white;
    height: 40px;
}

.btn-secondary {
    border-radius: 5px;
    margin: 2px;
    background-color: white;
    border-color: black;
    border-style: solid;
    border-width: 1px;
    color: var(--primary);
    height: 40px;
}

.cent {
    margin-left: auto;
    margin-right: auto;
}

.btn-tertiary {
    border-radius: 5px;
    margin: 2px;
    background-color: white;
    border-color: black;
    border-style: solid;
    border-width: 1px;
    color: black;
    height: 40px;
}

.col-2_4 {
    flex: 0 0 auto;
    width: 20% !important;
    display: inline-block;
}

.pricol {
    color: var(--primary);
}

.mobile {
    @media screen and (min-width:576px) {
        display: none;
    }
}

.desktop {
    @media screen and (max-width:575px) {
        display: none;
    }
}

.mobile700 {
    @media screen and (min-width:700px) {
        display: none;
    }
}

.desktop700 {
    @media screen and (max-width:699px) {
        display: none;
    }
}

.col-sm-2_4 {
    @media screen and (min-width:576px) {


        flex: 0 0 auto;
        width: 20% !important;
        display: inline-block;
    }
}
.col-md-2_4 {
    @media screen and (min-width:768px) {


        flex: 0 0 auto;
        width: 20% !important;
        display: inline-block;
    }
}

.card {
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
}

a:link {
    text-decoration: none;
    color: black;
}


a:visited {
    text-decoration: none;
    color: black;
}


a:hover {
    text-decoration: none;
    color: black;
}


a:active {
    text-decoration: none;
    color: black;
}

a {
    color: black;
}
html, body { height: 100%; }

body { margin: 0;}

.fake-link {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}

.fake-link-bot {
    color: black;
    text-decoration: none;
    cursor: pointer;
}
.fake-link-service {
    color: #00f !important;
    text-decoration: underline;
    cursor: pointer;
}
.fake-link-service:hover {
    color: #00a !important;
}
.why-service-text {
    width: clamp(400px,80%,700px);
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 30px;
}

.contentdiv{
    .col-sm-5 {
        @media screen and (max-width: 913px) {      
            flex: 0 0 auto !important;
            width: 100% !important;
            margin-left: unset !important;
        }
    }
        
}


.keyfeaturemain {
    .max {
        max-width: 1200px !important;
        text-align: center;

        .left {
            text-align: left;
        }

        .size-50 {
            font-size: 50px;
        }
        .bg-dark-grey {
            background-color: #3B3B3B;
        }
        li::marker {
            color: black !important;
        }
    }
    .under-heading {
        font-size: 0.6rem !important;
    }

    .marginRight {
        margin-right: 60px;

        @media screen and (max-width: 767.9px) {
            margin-right: 0px;
            
            li {
                padding-bottom: 5px;
            }
        }
        
        @media screen and (max-width: 549.9px) {
            li {
                font-size: 0.8rem !important;
            }
        }
    }

    .dot {
        cursor: pointer;
        height: 15px;
        width: 15px;
        margin: 0 2px;
        background-color: #d5d5d5;
        border-radius: 50%;
        display: inline-block;
    }

    .tabh {
        @media screen and (max-width: 549.9px) {
            font-size: 0.9rem !important;
        }
    }

    .active-dot, .dot:hover {
        background-color: var(--primary);
    }

    .horizontal {
        display:flex;
        justify-content: center;
        align-items: center;
    }

    .buttongroup {
      text-align: center;
      .btn-primary {
        padding-bottom: 4px;
        margin: 0 1rem 1rem 1rem;
        height: 50px;
        font-size: 1.2rem;
        width: 125px;
      }
    }

    .top-row {
        margin-left: 30px;
        padding-right: 30px;
    }

    .image-banner {
        border-radius: 8px;
        box-shadow: 0 .5rem 1rem #00000026 !important;
    }

    .imageOne {
        display: initial;
    }
      
    .imageTwo {
        display: none;
    }
      
    @media only screen and (max-width: 767.9px) {
        .imageOne {
            display: none;
        }
        .imageTwo {
            display: initial;
        }
    }

    .imgAspect {
        aspect-ratio: 16/9 !important;
    }

    p {
        font-size: 0.9rem;
        text-wrap: pretty;
    }

    li {
        font-size: 0.9rem;
        list-style-type: disc;
    }

    li::marker {
        color: red;
    }

    ul {
        margin-bottom: 0 !important;
    }

    .bus-padding {
        padding-top: 0.5rem !important;
    }

    h3 {
        font-size: 1.15rem !important;
        text-wrap: pretty;
    }
    h2 {
        font-size: 1.5rem !important;
        text-wrap: pretty;
    }
    h1 {
        font-size: 2rem !important;
        text-wrap: pretty;
    }
    .ImageCarouselLabel {
      font-size: 1.75rem;
      color: black;
    }
    .colour-title {
        color: var(--primary);
    }
    .padding-left {
        padding-left: 9%;
    }
    .padding-all {
        padding: 0 32px;
    }
    .paddingTop {
        padding-top: 12px;
    }
    .padding-title {
        padding: 16px 45px 0 45px;
        color: var(--primary);
    }
    .padding-title-accordion {
        padding: 16px 26px 0 42px;
    }
    .title-underline {
        border-bottom-style: inset;
        border-bottom-color: var(--primary);
    }
    .padding-subtitle {
        padding: 1.5rem 0 0.5rem 0;
    }
    .padding-content {
        padding: 0 4px 0 22px;
    }
    .half-width {
        padding-top: 12px;
      @media screen and (min-width: 767.1px) {
        width: 50%;
      }
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
    .background-grey {
      background-color: #F4F4F4;
    }
    .parentdiv {
         margin: auto;
        .operation-image {
            align-self: center;
            padding: 0 11px 0 0;
            @media screen and (max-width: 767px) {
                padding: 0;
              }
        }
    }
    .parentdiv:after { 
      content: " "; 
      display: block;
      clear: both;
    }
    .x-large {
        font-size: x-large!important;
        text-wrap: pretty;
    }
    .xx-large {
        font-size: xx-large!important;
        text-wrap: pretty;
    }
    .xxx-large{
        font-size: xxx-large!important;
        text-wrap: pretty;
    }
  }
  
  .footerbuttongroup {
    padding-top: 8px;

    .marginTopButtons {
        @media screen and (max-width: 549.9px) {
            margin-top: 16px !important;
        }
    }

    .btn-primary {
        padding-bottom: 2px;
        margin: 16px 1rem 24px;
        height: 40px;
        font-size: 1rem;
        width: 10%;
        min-width: 150px;

        @media screen and (max-width: 549.9px) {
            margin: 0 5rem 24px;
          }
    }
  }

  .featureList {
    .row {
        margin-left: 0px !important;
    }
  }

.calenda-hover:hover{
    filter: brightness(130%);
}
.calenda-hover-active:hover{
    filter: brightness(80%);
}

.cursor-pointer{
    cursor: pointer !important;
}

.table-layout-fixed{
    table-layout: fixed !important;
}